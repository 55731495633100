import { DateTime } from 'luxon';
import { ApiService } from './ApiService';
import apiResponse from './ApiResponse';

type Task = {
  id: number;
  name: string;
  dueDate: DateTime;
  completed: boolean;
  cohortUserId: number;
};

export class TaskStore {
  constructor(private readonly api: ApiService = new ApiService()) {}

  public async ReadGroupTasks(id: string, groupId: number) {
    try {
      const response = await this.api.get(`/cohortuser/${groupId}/user/${id}/tasks`);
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}

export type { Task };
