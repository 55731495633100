import { ApiService } from './ApiService';
import apiResponse from './ApiResponse';
import { CohortUser } from '@models/CohortUser';

export class CohortUserStore {
  constructor(private readonly api: ApiService = new ApiService()) {}

  public async readById(cohortUserId: number) {
    try {
      const response = await this.api.get(`/CohortUser/${cohortUserId}`);
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  public async update(cohortUser: CohortUser, cohortId: number) {
    const cohortUserToUpdate = {
      cohortId: cohortId,
      id: cohortUser.id,
      userId: cohortUser.userId,
      isActive: !cohortUser.isActive,
    };

    try {
      const response = await this.api.put('/CohortUser', cohortUserToUpdate);
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}
