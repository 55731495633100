import { ApiService } from './ApiService';
import apiResponse from './ApiResponse';

export enum NotificationType {
  TEAM_CHAT = 1,
  DIRECT_MESSAGE = 2,
}

export type Notification = {
  userId: number;
  message: string;
  title: string;
  groupId: number;
  groupName: string;
  notificationType: NotificationType;
  recipientId?: number;
};

export class NotificationService {
  constructor(private readonly api: ApiService = new ApiService()) {}

  public async sendNotification(notification: Notification) {
    try {
      const response = await this.api.post('/notifications/send', notification);
      return apiResponse.Success<Notification>(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}
